<template>
  <div class="layout-news-smilararticles">
    <UtilWrapperLayout
      v-if="isArrayNotEmpty(similarArticles)"
      class="layout-news-smilararticles__similar-article-wrapper"
    >
      <UtilGrid class="layout-news-smilararticles__grid">
        <div class="layout-news-smilararticles__similar-article is-wide">
          <div class="layout-news-smilararticles__similar-article-head">
            <AtomHeading
              class="layout-news-smilararticles__similar-article-title"
              :text="getStoryblokTranslation('general', 'similar_articles')"
              html-tag="h3"
              font-size="h3"
            />
            <div class="layout-news-smilararticles__controls">
              <ProviderColorSchema :color-schema="colorSchema" style="display: flex;">
                <IonIcon
                  v-if="!isBeginning"
                  icon-name="chevron-left"
                  @click="changeSlide('prev')"
                />
              </ProviderColorSchema>
              <p>
                {{ activeIndex }}/{{ totalSlides }}
              </p>
              <ProviderColorSchema :color-schema="colorSchema" style="display: flex;">
                <IonIcon
                  v-if="activeIndex < totalSlides"
                  icon-name="chevron-right"
                  @click="changeSlide('next')"
                />
              </ProviderColorSchema>
            </div>
          </div>
        </div>
        <div class="layout-news-smilararticles__wrapper is-wide">
          <div class="layout-news-smilararticles__similar-article-swiper-wrapper">
            <Swiper
              :slides-per-view="clientDeviceStore.isMobile ? 'auto' : 2.8"
              :space-between="20"
              :grab-cursor="true"
              class="layout-news-smilararticles__swiper"
              @swiper="onSwiper"
              @slide-change="onSlideChange"
            >
              <SwiperSlide
                v-for="article in similarArticles"
                :key="article._uid"
                class="layout-news-smilararticles__swiper-card"
              >
                <BlockItemTeaser
                  :item="{ ...article }
                  "
                  news-section
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    extraContent: {
        type: Object,
        required: true,
    },

    sbStoryId: {
        type: String,
        required: true,
    },
});
/*
  Variables
*/
const { locale: currentLocale, defaultLocale } = useI18n();
const colorSchema = computed(() => props.extraContent.department?.content.color_schema);
const color1 = computed(() => `var(--c-${colorSchema.value}-1)`);

/*
  Init Swiper
*/
const { Swiper, SwiperSlide } = useSwiperjs();
const swiperController = ref(null);
const totalSlides = ref(0);
const activeIndex = ref(1);
const isBeginning = ref(true);
const isEnd = ref(false);
const clientDeviceStore = useClientDeviceStore();

const onSwiper = (e) => {
    swiperController.value = e;
};

// watch swiperController to init totalSlides
watchEffect(() => {
    if (swiperController.value) {
        totalSlides.value = swiperController.value.snapGrid.length - 1;

        if (clientDeviceStore.isMobile) {
            totalSlides.value += 1;
        }
    }
});

const slideFromEnd = () => {
    if (isEnd.value && !clientDeviceStore.isMobile) {
        isEnd.value = false;
        swiperController.value.slideTo(totalSlides.value - 2);
    }
};

const changeSlide = (dir) => {
    if (dir === 'next') {
        swiperController.value.slideNext();
    }

    if (dir === 'prev') {
        if (isEnd.value && !clientDeviceStore.isMobile) {
            slideFromEnd();
        } else {
            swiperController.value.slidePrev();
        }
    }
};

const onSlideChange = (slide) => {
    activeIndex.value = slide.realIndex + 1;
    isBeginning.value = slide.isBeginning;

    if (!isEnd.value && activeIndex.value === totalSlides.value && !clientDeviceStore.isMobile) {
        // slide to the last slide + 1 to show the last slide
        if (swiperController.value) {
            swiperController.value.slideTo(totalSlides.value);
            isEnd.value = true;
        }
    }
};

const storyblokApi = useStoryblokApi();
const language = computed(() => (currentLocale.value === defaultLocale.value ? '' : currentLocale.value));

const {
    data: similarArticles,
    error: getSimilarArticlesError,
} = useLazyAsyncData(
    `get-similar-articles-${getUid()}`,
    async () => storyblokApi.get('cdn/stories/', {
        starts_with: 'news/news-article/',
        version: 'published',
        sort_by: 'first_published_at:desc',
        per_page: 5,
        filter_query: {
            department: {
                in: props.extraContent.department.uuid,
            },
        },
        excluding_ids: props.sbStoryId,
        resolve_relations: 'News.department,News.tags,News.author,News.type',
        language: language.value,
    }),
    {
        transform: (res) => {
            const articlesData = res.data.stories.map((article) => {
                const department = article.content?.department || {};
                const imageVideo = article.content?.image_video || {};

                return {
                    article: {
                        title: article.content.title,
                        cached_url: article.default_full_slug,
                        story: {
                            alternates: article.translated_slugs,
                        },
                        date: article.first_published_at,
                        department: {
                            name: department.translated_slugs?.find(
                                (slug) => slug.lang === currentLocale.value,
                            )?.name || department?.name,
                            color_3: department.content?.color_3,
                        },
                        textColor: department.content?.color_1,
                        dateColor: department.content?.color_3,
                        image: {
                            ...imageVideo,
                            provider: 'storyblok',
                        },
                    },
                    size: 'small',
                };
            });

            // reset swiper
            if (swiperController?.value) {
                swiperController.value.slideTo(0);
            }

            return articlesData;
        },
        watch: [props.extraContent],
    },
);

/*
    Error handling
*/
watch(() => getSimilarArticlesError, (error) => {
    if (error) {
        useSentryError(error, {
            starts_with: 'news/news-article/',
            version: 'published',
            sort_by: 'first_published_at:desc',
            per_page: 5,
            filter_query: {
                department: {
                    in: props.extraContent.department.uuid,
                },
            },
            excluding_ids: props.sbStoryId,
            resolve_relations: 'News.department,News.tags,News.author,News.type',
            language: language.value,
        });
    }
});
</script>

<style lang="scss">
.layout-news-smilararticles {
    position: relative;
    background-color: var(--c-background);
}

.layout-news-smilararticles__controls {
    display: flex;
    align-items: center;
    color: v-bind(color1);
    column-gap: 10px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    p {
        margin-top: 6px;

        @include mobile {
            margin-top: 1px;
        }
    }

    .ion-icon {
        cursor: pointer;
    }
}

.layout-news-smilararticles__swiper {
    display: flex;

    @include tablet {
        display: flex;
        overflow: visible;
        flex-direction: row;

        .layout-news-smilararticles__swiper-card {
            min-width: 270px;
        }

        .block-item-teaser {
            width: 270px !important;
            margin-bottom: 10px;
        }

        .swiper-slide {
            width: 270px !important;
        }
    }
}

.layout-news-smilararticles__hero-article-tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 10px;

    @include mobile{
        margin-bottom: 20px;
    }
}

.layout-news-smilararticles__similar-article-wrapper {
    overflow: hidden;
}

.layout-news-smilararticles__similar-article {
    @include default-content-columns;

    display: flex;
    flex-direction: column;
}

.layout-news-smilararticles__grid {
    margin-bottom: 72px;

    &.block-grid {
        row-gap: 20px;
    }
}

.layout-news-smilararticles__similar-article-head {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px 0 30px;
    background-color: var(--c-white);
}

.layout-news-smilararticles__similar-article-title {
    &.atom-heading {
        color: v-bind(color1);
        font-family: var(--f-family--black);
    }
}

.layout-news-smilararticles__similar-article-swiper-wrapper {
    width: 100%;
}

.layout-news-smilararticles__wrapper {
    @include default-content-columns;
}

.layout-news-smilararticles__imgWrapper {
    overflow: hidden;

    img {
        height: 201px;
    }
}

.layout-news-smilararticles__tag {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 125px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news-smilararticles__video-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 92px;
    height: 50px;
    align-items: center;
    justify-content: space-around;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news-smilararticles__date {
    margin-top: 10.87px;
    color: v-bind(colorDate);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news-smilararticles__title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}

.layout-news-smilararticles__lead {
    p {
        margin-bottom: 20px;
    }
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news-smilararticles__type {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news-smilararticles__link {
    padding-top: 20px;
}

.layout-news-smilararticles__icon {
    transition: all 0.2s ease-out;
}

.layout-news-smilararticles__img {
    transition: transform 0.2s ease-out;
}

.layout-news-smilararticles__similar-article-wrapper-item {
    &:hover {
        .layout-news-smilararticles__img {
            transform: scale(1.05);
        }

        .layout-news-smilararticles__video-tag-icon {
            transform: none;
        }

        .layout-news-smilararticles__icon {
            transform: translateX(10px);
        }
    }
}

.layout-news-smilararticles__hero-article-details-author {
    color: var(--c-department-4);
}
</style>
