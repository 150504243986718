<template>
  <div class="block-teaser-news">
    <UtilWrapperLayout>
      <UtilGrid class="block-teaser-news__grid">
        <div class="block-teaser-news__heading is-wide">
          <h4 class="block-teaser-news__title">
            {{ title }}
          </h4>
          <div class="block-teaser-news__controls">
            <IonIcon
              v-if="!isBeginning"
              icon-name="chevron-left"
              @click="changeSlide('prev')"
            />
            <p>
              {{ activeIndex }}/{{ totalSlides }}
            </p>
            <IonIcon
              v-if="activeIndex < totalSlides"
              icon-name="chevron-right"
              @click="changeSlide('next')"
            />
          </div>
        </div>
        <div class="block-teaser-news__wrapper is-wide">
          <ClientOnly>
            <div
              v-if="articlesStatus === 'success'"
              class="block-teaser-news__swiper-wrapper"
            >
              <Swiper
                :slides-per-view="clientDeviceStore.isMobile ? 'auto' : 2.8"
                :space-between="20"
                :grab-cursor="true"
                class="block-teaser-news__swiper"
                @swiper="onSwiper"
                @slide-change="onSlideChange"
                @from-edge="slideFromEnd"
              >
                <SwiperSlide
                  v-for="article in articles"
                  :key="article._uid"
                  class="block-teaser-news__swiper-card"
                >
                  <BlockItemTeaser
                    :item="{ ...article }"
                    news-section
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </ClientOnly>

          <UtilRouteLink
            v-if="ctaLink?.id || ctaLink?.url"
            class="block-teaser-news__link"
            :link="ctaLink"
          >
            <AtomButton :text="ctaTitle" html-tag="div" />
          </UtilRouteLink>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    subtitle: {
        type: String,
        default: '',
    },

    departments: {
        type: Array,
        default: () => [],
    },

    country: {
        type: String,
        default: null,
    },

    ctaTitle: {
        type: String,
        default: '',
    },

    ctaLink: {
        type: [String, Object],
        default: () => {},
    },

    isInternational: {
        type: Boolean,
        default: false,
    },
});

/*
    Init Swiper
*/
const { Swiper, SwiperSlide } = useSwiperjs();
const swiperController = ref(null);
const totalSlides = ref(0);
const activeIndex = ref(1);
const isBeginning = ref(true);
const isEnd = ref(false);
const clientDeviceStore = useClientDeviceStore();

const onSwiper = (e) => {
    swiperController.value = e;
};

// watch swiperController to init totalSlides
watchEffect(() => {
    if (swiperController.value) {
        totalSlides.value = swiperController.value.snapGrid.length - 1;

        if (clientDeviceStore.isMobile) {
            totalSlides.value += 1;
        }
    }
});

const slideFromEnd = () => {
    if (isEnd.value && !clientDeviceStore.isMobile) {
        isEnd.value = false;
        swiperController.value.slideTo(totalSlides.value - 2);
    }
};

const changeSlide = (dir) => {
    if (dir === 'next') {
        swiperController.value.slideNext();
    }

    if (dir === 'prev') {
        if (isEnd.value && !clientDeviceStore.isMobile) {
            slideFromEnd();
        } else {
            swiperController.value.slidePrev();
        }
    }
};

const onSlideChange = (slide) => {
    activeIndex.value = slide.realIndex + 1;
    isBeginning.value = slide.isBeginning;

    if (!isEnd.value && activeIndex.value === totalSlides.value && !clientDeviceStore.isMobile) {
        // slide to the last slide + 1 to show the last slide
        swiperController.value.slideTo(totalSlides.value);
        isEnd.value = true;
    }
};

const departmentIds = computed(() => props.departments);
const countryIds = computed(() => props.country);

const internationalDepartmentUUID = '6e8e4951-ab05-4981-80f4-736c9b4c7646';

const storyblokApi = useStoryblokApi();

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const createFilterQuery = (isInternational) => {
    const filter = {};

    if (isInternational) {
        if (countryIds.value && !Array.isArray(countryIds.value)) {
            filter.country = {
                in: countryIds.value,
            };
        } else {
            filter.department = { in: internationalDepartmentUUID };
        }

        return filter;
    }

    if (departmentIds.value.length > 0) {
        filter.department = {
            in: departmentIds.value.join(','),
        };
    } else {
        filter.country = { in: '' };
    }

    return filter;
};

const {
    data: articles,
    status: articlesStatus,
    error: articlesError,
} = useLazyAsyncData(
    `get-news-teaser-articles-${getUid()}`,
    async () => storyblokApi.get('cdn/stories/', {
        starts_with: 'news/news-article',
        version: 'published',
        resolve_links: 'link',
        resolve_relations: 'News.department,News.type,News.country',
        filter_query: createFilterQuery(props.isInternational),
        sort_by: 'first_published_at:desc',
        language,
    }),
    {
        transform: (res) => {
            const fetechedArticles = [];
            res.data.stories.forEach((article) => {
                fetechedArticles.push({
                    article: {
                        title: article.content.title,
                        cached_url: article.default_full_slug,
                        story: {
                            alternates: article.translated_slugs,
                        },
                        date: article.first_published_at,
                        department: {
                            name: article.content.department.translated_slugs?.find(
                                (slug) => slug.lang === currentLocale.value,
                            )?.name || article.content.department?.name,
                            color_3: article.content.department.content.color_3,
                        },
                        image: {
                            ...article.content.image_video,
                            provider: 'storyblok',
                        },
                    },
                    size: 'small',
                });
            });

            const slicedArticles = fetechedArticles.slice(0, 5);

            return slicedArticles;
        },
    },
);

/*
    Error handling
*/
watch(
    () => articlesError.value,
    (error) => {
        if (error) {
            useSentryError(error, {
                starts_with: 'news/news-article',
                version: 'published',
                resolve_relations: 'News.department,News.type,News.country',
                filter_query: createFilterQuery(props.isInternational),
                sort_by: 'first_published_at:desc',
                language,
            });
        }
    },
);

const colorTopLevel = inject('colorSchema');
const colorMain = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss">
.block-teaser-news {
    @include fluid('margin-bottom', 70px, 82px);

    overflow: hidden;
}

.block-teaser-news__wrapper {
    @include default-content-columns;
}

.block-teaser-news__grid {
    row-gap: 20px !important;
}

.block-teaser-news__heading {
    @include default-content-columns;

    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px 0 30px;
    background-color: var(--c-white);
    color: v-bind(colorMain);
}

.block-teaser-news__title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
}

.block-teaser-news__controls {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    p {
        margin-top: 6px;

        @include mobile {
            margin-top: 1px;
        }
    }

    .ion-icon {
        cursor: pointer;
    }
}

.block-teaser-news__swiper-wrapper {
    width: 100%;
}

.block-teaser-news__swiper {
    display: flex;

    @include tablet {
        display: flex;
        overflow: visible;
        flex-direction: row;

        .block-teaser-news__swiper-card {
            min-width: 270px;
        }

        .block-item-teaser {
            width: 270px !important;
            margin-bottom: 10px;
        }

        .swiper-slide {
            width: 270px !important;
        }
    }
}
</style>
