<template>
  <div class="layout-news-hero">
    <UtilWrapperLayout>
      <UtilGrid>
        <div v-if="extraContent" class="layout-news-hero__hero-article">
          <div
            class="layout-news-hero__hero-article-back"
            :style="{
              color: extraContent.department?.content?.color_4,
            }"
            @click="backToOverview()"
            @keypress="backToOverview()"
          >
            <ProviderColorSchema :color-schema="colorSchema" style="display: flex;">
              <IonIcon icon-name="chevron-left" :color-schema="colorSchema" />
            </ProviderColorSchema>
            <p>
              {{ getStoryblokTranslation('general', 'back_to_overview') }}
            </p>
          </div>

          <div class="layout-news-hero__hero-article-image">
            <AtomImage
              v-if="extraContent?.image_video?.filename"
              class="layout-news-hero__hero-article-image"
              :image="extraContent.image_video"
              provider="storyblok"
              :lazy="false"
              height="518px"
              width="960px"
            />
          </div>

          <div
            v-if="isArrayNotEmpty(extraContent?.tags)"
            class="layout-news-hero__hero-article-tags"
          >
            <p
              v-for="tag in computedTags"
              :key="tag.index"
              class="layout-news-hero__hero-article-tag"
              :style="{
                'background-color': extraContent.department.content.color_11,
                color: extraContent.department.content.color_6,
              }"
            >
              {{ tag.name }}
            </p>
          </div>

          <div
            v-if="extraContent?.department?.name || extraContent?.type?.name"
            class="layout-news-hero__hero-article-department-type"
            :style="{
              color: extraContent.department?.content?.color_4,
            }"
          >
            <p v-if="extraContent?.department?.name">
              {{ computedDepartment }}
            </p>
            <p v-if="extraContent?.type?.name">
              |
            </p>
            <p v-if="extraContent?.type?.name">
              {{ computedType }}
            </p>
          </div>

          <AtomHeading
            v-if="extraContent?.title"
            class="layout-news-hero__hero-article-title"
            :class="{ 'has-subtitle': extraContent.sub_title }"
            :text="extraContent.title"
            html-tag="h2"
            font-size="h2"
            :style="{
              color: extraContent.department?.content?.color_1,
            }"
          />

          <AtomHeading
            v-if="extraContent?.sub_title"
            class="layout-news-hero__hero-article-subtitle"
            :text="extraContent.sub_title"
            html-tag="h2"
            font-size="h2"
            :style="{
              color: extraContent.department?.content?.color_1,
            }"
          />

          <div class="layout-news-hero__hero-article-details">
            <p
              :style="{
                color: extraContent.department?.content?.color_3,
              }"
            >
              {{ dayjs(firstPublishedAt).locale(currentLocale).format('DD. MMMM YYYY') }}
            </p>
            <p v-if="extraContent.author">
              |
            </p>
            <span
              v-if="extraContent?.author"
              class="layout-news-hero__hero-article-details-author"
              :style="`--c-department-4: ${extraContent.department.content.color_4};`"
            >
              {{ extraContent.author.name }}
            </span>
            <div class="layout-news-hero__hero-article-details-share">
              <div
                @click="share()"
                @keypress="share()"
              >
                <ProviderColorSchema :color-schema="colorSchema" style="display: flex;">
                  <IonIcon
                    icon-name="share"
                  />
                </ProviderColorSchema>
              </div>
              <AtomSocialShare
                v-for="network in networks"
                :key="network.network"
                :network="network.network"
                :url="baseUrl + route.fullPath"
                :title="extraContent.title"
                :description="extraContent.sub_title"
                :image="extraContent.image_video.filename"
              >
                <ProviderColorSchema :color-schema="colorSchema" style="display: flex;">
                  <IonIcon
                    :icon-name="network.network"
                  />
                </ProviderColorSchema>
              </AtomSocialShare>
              <p
                v-if="isArrayNotEmpty(voices)"
                :style="{
                  color: extraContent.department?.content?.color_4,
                }"
              >
                |
              </p>
              <div class="layout-news__reader-wrapper">
                <div
                  v-if="isArrayNotEmpty(voices)"
                  class="layout-news__reader"
                  :style="{
                    color: extraContent.department?.content?.color_4,
                  }"
                  @click="readText()"
                  @keypress="readText()"
                >
                  <div>{{ getStoryblokTranslation('general', 'read_article') }}</div>
                  <ProviderColorSchema :color-schema="colorSchema" style="display: flex; gap: 10px;">
                    <IonIcon
                      v-if="speechStatus === 'stopped'"
                      icon-name="reader"
                    />
                    <IonIcon
                      v-if="speechStatus === 'playing'"
                      class="layout-news__reader-icon"
                      icon-name="pause"
                    />
                    <IonIcon
                      v-if="speechStatus === 'paused'"
                      class="layout-news__reader-icon"
                      icon-name="resume"
                    />
                  </ProviderColorSchema>
                </div>
                <ProviderColorSchema :color-schema="colorSchema" style="display: flex;">
                  <IonIcon
                    v-if="speechStatus === 'paused' || speechStatus === 'playing'"
                    icon-name="stop"
                    @click="readText('stop')"
                    @keypress="readText('stop')"
                  />
                </ProviderColorSchema>
              </div>
            </div>
          </div>

          <AtomLead
            v-if="extraContent?.lead"
            class="layout-news-hero__hero-article-lead"
            :text="extraContent.lead"
            :style="{
              color: extraContent.department?.content?.color_1,
            }"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
import { useSpeechSynthesis } from '@vueuse/core';

const props = defineProps({
    extraContent: {
        type: Object,
        default: () => ({}),
    },

    firstPublishedAt: {
        type: String,
        default: '',
    },

    mainText: {
        type: String,
        default: '',
    },
});

const colorSchema = computed(() => props.extraContent.department?.content.color_schema);

/*
  Variables
*/
const { dayjs } = useDayJs();
const route = useRoute();
const localePath = useLocalePath();
const runtimeConfig = useRuntimeConfig();
const baseUrl = computed(() => runtimeConfig.public.BASE_URL);
const storyblokContentStore = useStoryblokContentStore();
const overviewPaths = computed(() => storyblokContentStore.getOverviewPath);
const { locale: currentLocale } = useI18n();
const { isMobile } = storeToRefs(useClientDeviceStore());

const networks = [
    { network: 'linkedin', icon: ['linkedin'] },
];

/*
    Functions
*/
const backToOverview = () => {
    navigateTo(localePath(overviewPaths.value));
};

const share = () => {
    // share url mobile
    if (navigator.share) {
        navigator.share({
            title: props.extraContent.title,
            text: props.extraContent.sub_title,
            url: baseUrl + route.fullPath,
        })
            .then(() => {})
            .catch((error) => useSentryError(error));
    } else {
        // share url desktop
        window.location.href = `mailto:?subject=${props.extraContent.title}&body=${props.extraContent.sub_title}%0D%0A%0D%0A${baseUrl.value + route.fullPath}`;
    }
};

const computedTags = computed(() => props.extraContent.tags?.map((tag) => ({
    ...tag,
    translated_slugs: tag.translated_slugs?.find((slug) => slug.lang === currentLocale.value),
    name: tag.translated_slugs?.find((slug) => slug.lang === currentLocale.value)?.name || tag.name,
})));

const computedType = computed(() => props.extraContent.type?.translated_slugs?.find(
    (slug) => slug.lang === currentLocale.value,
)?.name || props.extraContent.type.name);

const computedDepartment = computed(
    () => props.extraContent.department?.translated_slugs?.find(
        (slug) => slug.lang === currentLocale.value,
    )?.name || props.extraContent.department.name,
);

/*
    Text to Speech with Chunking
*/
const textChunks = ref([]); // To hold the chunks of text
const selectedVoice = ref(null);
const selectedLang = ref(null);
const speechStatus = ref('stopped');

// Initialize the speech synthesis object
const speech = useSpeechSynthesis('', {
    pitch: isMobile.value ? 0.8 : 1,
    rate: currentLocale.value === 'fr' ? 1 : 1.1,
    volume: 1,
});

// Function to split text into chunks
const chunkText = (text, chunkSize = 180) => {
    const chunks = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
        let nextChunk = text.slice(currentIndex, currentIndex + chunkSize);

        // Find the last punctuation character before the chunk ends
        const lastPunctuationIndex = Math.max(
            nextChunk.lastIndexOf('.'),
            nextChunk.lastIndexOf(','),
            nextChunk.lastIndexOf('!'),
            nextChunk.lastIndexOf('?'),
            nextChunk.lastIndexOf(':'),
        );

        // If a punctuation mark is found and it's not at the very end of the chunk
        if (lastPunctuationIndex !== -1 && currentIndex + chunkSize < text.length) {
            nextChunk = nextChunk.slice(0, lastPunctuationIndex + 1);
        }

        chunks.push(nextChunk);
        currentIndex += nextChunk.length;

        // Skip any trailing spaces after the chunk
        while (text[currentIndex] === ' ') {
            currentIndex += 1;
        }
    }

    return chunks;
};

const voices = ref([]);

// Function to set voice based on the current locale
const setVoice = () => {
    if (currentLocale.value === 'de') {
        selectedLang.value = 'de-DE';
        selectedVoice.value = voices.value.find((voice) => voice.name === 'Google Deutsch')
        || voices.value.find((voice) => voice.name === 'Anna')
        || voices.value.find((voice) => voice.name === 'Eddy')
        || voices.value.find((voice) => voice.name === 'German Germany')
        || voices.value.find((voice) => voice.lang === 'de-DE')
        || voices.value.find((voice) => voice.lang === 'de_DE');
    } else if (currentLocale.value === 'fr') {
        selectedLang.value = 'fr-FR';
        selectedVoice.value = voices.value.find((voice) => voice.name === 'Google français')
        || voices.value.find((voice) => voice.name === 'Amélie')
        || voices.value.find((voice) => voice.name === 'French France')
        || voices.value.find((voice) => voice.lang === 'fr-FR')
        || voices.value.find((voice) => voice.lang === 'fr_FR');
    } else if (currentLocale.value === 'it') {
        selectedLang.value = 'it-IT';
        selectedVoice.value = voices.value.find((voice) => voice.name === 'Google italiano')
        || voices.value.find((voice) => voice.name === 'Alice')
        || voices.value.find((voice) => voice.name === 'Italian Italy')
        || voices.value.find((voice) => voice.lang === 'it-IT')
        || voices.value.find((voice) => voice.lang === 'it_IT');
    } else {
        selectedLang.value = 'en-GB';
        selectedVoice.value = voices.value.find((voice) => voice.name === 'Google UK English Female')
        || voices.value.find((voice) => voice.name === 'Samantha')
        || voices.value.find((voice) => voice.name === 'English United Kingdom')
        || voices.value.find((voice) => voice.lang === 'en-GB')
        || voices.value.find((voice) => voice.lang === 'en_GB');
    }

    // If the voice is found, update the speech object
    if (selectedVoice.value) {
        speech.utterance.value.voice = selectedVoice.value;
        speech.utterance.value.lang = selectedLang.value;

        if (selectedVoice.value.name === 'Eddy') {
            speech.utterance.value.rate = 1.1;
            speech.utterance.value.pitch = 1.2;
        }
    }
};

// Fetch the voices when the component is mounted
onMounted(() => {
    function loadVoices() {
        voices.value = window.speechSynthesis.getVoices();

        if (voices.value.length === 0) {
            setTimeout(loadVoices, 100);
        } else {
            setVoice();
        }
    }

    setTimeout(() => {
        window.speechSynthesis.getVoices();
        loadVoices();
    }, 100);
});

// Function to read each chunk
const readNextChunk = () => {
    if (textChunks.value.length > 0) {
        const currentChunk = textChunks.value.shift();
        speech.utterance.value.text = currentChunk;
        speech.speak();
        speech.utterance.value.onend = () => {
            if (textChunks.value.length > 0) {
                readNextChunk(); // Read the next chunk when current finishes
            } else {
                speech.stop();
            }
        };
    }
};

// Function to start reading the full text
const readText = (status) => {
    if (status === 'stop') {
        speech.stop();
        speechStatus.value = 'stopped';
    } else if (speechStatus.value === 'paused') {
        window.speechSynthesis.resume();
        speechStatus.value = 'playing';
    } else if (speechStatus.value === 'playing') {
        if (isMobile.value) {
            speech.stop();
            speechStatus.value = 'stopped';
        } else {
            window.speechSynthesis.pause();
            speechStatus.value = 'paused';
        }
    } else {
        speechStatus.value = 'playing';
        textChunks.value = chunkText(`${props.extraContent.lead + props.mainText}`); // Split the text into chunks
        readNextChunk();
    }
};

// watch currentLocale to update the voice
watch(currentLocale, () => {
    setVoice();
    speech.stop();
});

onBeforeUnmount(() => {
    speech.stop();
});
</script>

<style lang="scss" scoped>
.layout-news-hero {
    position: relative;
    background-color: var(--c-background);
}

.layout-news-hero__hero-article {
    @include default-content-columns;

    margin-top: 78px;
    margin-bottom: 33px;

    @include mobile {
        margin-top: 30px;
        margin-bottom: 40px;
    }
}

.layout-news-hero__hero-article-details-share {
    display: flex;
    align-items: center;
    column-gap: 29px;

    path {
        stroke: v-bind(strokeColor);
    }

    .ion-icon {
        cursor: pointer;
    }
}

.layout-news-hero__controls {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    p {
        margin-top: 6px;

        @include mobile {
            margin-top: 1px;
        }
    }

    .ion-icon {
        cursor: pointer;
    }
}

.layout-news-hero__swiper {
    display: flex;

    @include tablet {
        display: flex;
        overflow: visible;
        flex-direction: row;

        .layout-news-hero__swiper-card {
            min-width: 270px;
        }

        .block-item-teaser {
            width: 270px !important;
            margin-bottom: 10px;
        }

        .swiper-slide {
            width: 270px !important;
        }
    }
}

.layout-news-hero__hero-article-back {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 10px;
    color: var(--c-petrol-4);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .ion-icon {
        transition: all 0.3s ease;
    }

    p {
        padding-top: 1px;
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0)
        }
    }
}

.layout-news-hero__hero-article-image {
    @include fluid('margin-bottom', 10px, 19px);

    height: 518px;

    @include mobile {
        height: 152px;
    }
}

.layout-news-hero__hero-article-tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 10px;

    @include mobile{
        margin-bottom: 20px;
    }
}

.layout-news-hero__hero-article-tag {
    padding: 6px 12px;
    border-radius: 4px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news-hero__hero-article-department-type {
    @include fluid('margin-bottom', 5px, 10px);

    display: flex;
    column-gap: 9px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--ri--f-size--richtext);
}

.layout-news-hero__hero-article-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    column-gap: 9px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    row-gap: 5px;

    @include mobile {
        margin-bottom: 40px;
    }
}

.layout-news-hero__hero-article-title {
    @include fluid('margin-bottom', 20px, 30px);

    &.has-subtitle {
        margin-bottom: 0;
    }
}

.layout-news-hero__hero-article-subtitle {
    @include fluid('margin-bottom', 20px, 30px);

    &.atom-heading {
        font-family: var(--f-family--thin);
    }

}

.layout-news-hero__wrapper {
    @include default-content-columns;
}

.layout-news-hero__imgWrapper {
    overflow: hidden;

    img {
        height: 201px;
    }
}

.layout-news-hero__tag {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 125px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news-hero__video-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 92px;
    height: 50px;
    align-items: center;
    justify-content: space-around;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news-hero__date {
    margin-top: 10.87px;
    color: v-bind(colorDate);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news-hero__title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}

.layout-news-hero__lead {
    p {
        margin-bottom: 20px;
    }
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news-hero__type {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news-hero__link {
    padding-top: 20px;
}

.layout-news-hero__icon {
    transition: all 0.2s ease-out;
}

.layout-news-hero__img {
    transition: transform 0.2s ease-out;
}

.layout-news-hero__hero-article-details-author {
    color: var(--c-department-4);
}

.layout-news__reader {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    gap: 10px;
    line-height: var(--l-height--richtext);
}

.layout-news__reader-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.layout-news__reader-icon {
    @include tablet {
        display: none;
    }
}

.layout-news-hero__hero-article-lead {
    font-family: var(--f-family--thin);
}
</style>
