<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-quote is-narrow">
        <AtomLead v-if="text" class="storyblok-quote__text" :text="text" />
        <p v-if="author" class="storyblok-quote__author">
          {{ author }}
        </p>
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
defineProps({
    text: {
        type: String,
        default: '',
    },

    author: {
        type: String,
        default: '',
    },
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color6 = computed(() => `var(--c-${colorTopLevel.value}-6)`);
</script>

<style lang="scss" scoped>
.storyblok-quote {
    @include fluid('margin-bottom', 30px, 50px);

    grid-column: 6 / span 8;

    @include tablet {
        @include grid-columns(1, calc(var(--grid-columns) - 1));
    }

    @include mobile {
        @include grid-columns(1, var(--grid-columns));
    }
}

.storyblok-quote__author {
    color: v-bind(color1);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.storyblok-quote__text {
    @include fluid('margin-bottom', 5px, 10px);

    color: v-bind(color6);
    font-family: var(--f-family--thin);
}
</style>
